import React, { useState } from 'react';

const QrScanAlreadyRecievedModal = () => {
  return (
    <>
      <div className="text-center text-black text-lg font-light">
      <img src="/images/whoops.svg" className="
        w-10 h-10 
        mb-[12px]
        inline-block
      "/>
      </div>
      <div class="text-center text-black text-lg font-light">Uh Oh!</div>
      <div class="mt-[7px] text-center text-black text-sm font-light">
        You have already found this clue! Keep looking for new ones.
      </div>
    </>
  )
}

export default QrScanAlreadyRecievedModal