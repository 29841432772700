
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      'DsIndigo',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  components: {
  },
  palette: {
    primary: {
      main: "#FF5252",
      dark: "#FF5252",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#C4C4C4",
      contrastText: "#000000",
      dark: "#C4C4C4"
    },
  }
});

export default theme;

