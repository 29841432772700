import React, { useState } from 'react';

const QrScanFailedModalContent = () => {
  return (
    <>
      <div className="text-center text-black text-lg font-light">
      <img src="/images/whoops.svg" className="
        w-10 h-10 
        mb-[12px]
        inline-block
      "/>
      </div>
      <div class="text-center text-black text-lg font-light">Whoops!</div>
      <div class="mt-[7px] text-center text-black text-sm font-light">
        That’s not a Discover QR code, try again.
      </div>
    </>
  )
}

export default QrScanFailedModalContent