import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useState } from 'react';
import DataGridTableToolbar from '../DataGridTableToolbar';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { 
  Button
} from "@mui/material";
import UserFormModal from './UserFormModal';

const UsersTable = () => {
  const confirm = useConfirm();

  const [ users, setUsers ] = useState([])
  const [ loading, setLoading ] = useState(true)
  
  const [ editingUserId, setEditingUserId ] = useState(null)
  const [ editUserOpen, setEditUserOpen ] = useState(false)

  const openUserEditModal = (id) => {
    setEditingUserId(id)
    setEditUserOpen(true)
  }

  const closeUserEditModal = () => {
    setEditingUserId(null)
    setEditUserOpen(false)
  }
  
  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = () => {
    fetch('/admin/api/users')
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setUsers(data.users)
          setLoading(false)
        }else {
          alert("An Error Occurred Loading Users")
          console.log(data.error)
        }

      }).catch((error) => {
        alert("An Error Occurred Loading Users")
        console.log(error)
      })
  }

  if(loading) {
    return (
      <p>Loading...</p>
    )
  }
  
  const columns = [
    { field: 'email', headerName: 'Email', flex: 3 },
    { field: 'created_at', sort: true, headerName: 'Created At', type: 'dateTime', flex: 1, valueGetter: (params) => new Date(params.value) },
    { field: "actions", flex: 1, headerName: "Actions", renderCell: (params) => (
      <>
        {params.row.email.indexOf("@jaguardesignstudio.com") == -1 ? (
          <Button onClick={() => attemptDeleteUser(params.row.id)}>Delete</Button>
        ):""}
        
      </>   
    )}
  ];

  const attemptDeleteUser = (id) => {
    confirm({
      description: "Are you sure you want to delete this user? This action cannot be undone",
      confirmationText: "Yes, delete",
      cancellationText: "No, keep",
    }).then(() => {
      fetch(`/admin/api/users/${id}`, {
        method: "DELETE",
      }).then(fetchUsers);
    })
  }

  const renderEditUserModal = () => {
    return (
      <UserFormModal
        id={editingUserId}
        open={editUserOpen}
        closeModal={closeUserEditModal}
        callbackSuccess={()=> {
          closeUserEditModal()
          fetchUsers()
        }}
      />
    )
  }

  return (
    <>
    <Button
      onClick={() => {setLoading(true); fetchUsers()}}
    > Refresh </Button>
    <Button
      onClick={() => {
        openUserEditModal(null)
      }}
    > Create User </Button>
    {renderEditUserModal()}
    <DataGridPremium
      //onCellEditCommit={saveComments}
      autoHeight
      unstable_headerFilters
      rows={users}
      columns={columns}
      slots={{
        toolbar: DataGridTableToolbar
      }}
      checkboxSelection={true}
      style={{
        cell: {
          paddingBottom: 0,
          paddingRight: 0,
          whiteSpace: 'normal',
          overflow: 'visible',
        },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'created_at', sort: 'desc' }],
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
        columns: {
          columnVisibilityModel: {
            hidden: false,
          },
        },
        filter: {
          filterModel: {
            items: [
              {
                field: 'hidden',
                operator: 'is',
                value: "false",
              }
            ]
          }
        }
      }}
      pageSizeOptions={[5, 10, 20, 50]}
    />
    </>
  );
};

export default UsersTable