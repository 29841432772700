import React, { useState } from 'react';
import {
  Button, 
  Link,
  Stack
} from "@mui/material";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const ActivityTableActivity = ({
  activity,
  attemptDeleteActivity,
  attemptEditActivity,
  attemptOpenQrMenu
}) => {
  return (
    <GridItem key={`activity-${activity.id}`}>
      <div className="mr-2 mb-2">
        <div
        className="  relative p-4 activity-table-activity w-full h-full bg-slate-300 rounded-md cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap ">
          <DragIndicatorIcon className="absolute top-2 right-2"/>
          <div className="activity-table-activity-title font-bold text-lg">
            {activity.name}
          </div>
          <div className="activity-table-activity-hint text-sm text-slate-700 text-ellipsis overflow-hidden whitespace-nowrap">
            {activity.clue}
          </div>
          <div className="activity-table-activity-actions">
            <Stack direction="row" spacing={2}> 
              <Button fullWidth variant="contained" color="primary" onClick={()=>attemptEditActivity(activity.id)}>Edit</Button>
              
              <Button fullWidth variant="contained" color="secondary" onClick={()=>attemptOpenQrMenu(activity.id)}>QR Codes</Button>
              <Button fullWidth variant="contained" color="error" onClick={()=>attemptDeleteActivity(activity.id)}>Delete</Button>
            </Stack>
          </div>
        </div>
      </div>
    </GridItem>
  )
}

export default ActivityTableActivity;