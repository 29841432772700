import React from "react";

const PlayerContext = React.createContext({
  player: {
    id: null,
    login_key: null,
    email: null,
    name_first: "",
    name_last: ""
  }
});

export default PlayerContext;
