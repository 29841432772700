import React, { useContext } from 'react';
import UrlContext from './context/UrlContext';
import PlayerContext from './context/PlayerContext';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro'
import GameRouter from './GameRouter';
import GameLayout from "./GameLayout";
import theme from "./DigitalMuiTheme";
import { ThemeProvider } from "@mui/material";
const GameApp = (props) => {
  const MUI_KEY = "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
  LicenseInfo.setLicenseKey(MUI_KEY);
  const { apiRoot, rootUrl, player} = props;

  const urlContext = {
    apiRoot,
    rootUrl,
  }

  const playerContext = {
    player
  };

  return (
    <UrlContext.Provider value={urlContext}>
        <PlayerContext.Provider value={playerContext}>
          <BrowserRouter basename={"/"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider theme={theme}>
                <ConfirmProvider>
                  <GameLayout>
                    <GameRouter />
                  </GameLayout>
                </ConfirmProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </PlayerContext.Provider>
    </UrlContext.Provider>
  );
};

export default GameApp