import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const MobilePreviewDashboard = () => {
  return (

      <Card sx={{
        marginTop: 2
      }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Mobile Preview
          </Typography>
          <iframe className="m-auto" src="/" width="360" height="640" title="Mobile Preview"/>
        </CardContent>
      </Card>
  )
}

export default MobilePreviewDashboard