import React, { useState, useEffect } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import ActivityTableActivity from './ActivityTableActivity';
import { useConfirm } from 'material-ui-confirm';
import { 
  Stack, 
  Button
} from "@mui/material";
import GeneralModal from '../../GeneralModal';
import ActivityForm from './ActivityForm';
import QrModalContents from './qr/QrModalContents';

const ActivitiesTable = () => {
  const confirm = useConfirm()
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [editingActivityId, setEditingActivityId] = useState(null)
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [editActivityOpen, setEditActivityOpen] = useState(false)

  useEffect(() => {
    fetchActivities()
  }, [])

  console.log(qrModalOpen)

  const attemptDeleteActivity = (id) => {
    confirm({
      description: 'Are you sure you want to delete this activity?'
    }).then(() => {
      fetch(`/admin/api/activities/${id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        if(!data.error) {
          fetchActivities()
        }
      });
    });
  }

  const attemptEditActivity = (id) => {
    setEditingActivityId(id)
    setQrModalOpen(false)
    setEditActivityOpen(true)
  }

  const fetchActivities = () => {
    fetch('/admin/api/activities')
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setActivities(data.activities)
          setLoading(false)
        }else {
          alert("An Error Occurred Loading Activities")
          console.log(data.error)
        }
      }).catch((error) => {
        alert("An Error Occurred Loading Activities")
        console.log(error)
      })
  }

  getSortedActivities = () => {
    return activities.sort((a,b) => {
        if(a.sort_order == b.sort_order) {
          return a.id < b.id ? -1 : 1
        }else {
          return a.sort_order < b.sort_order ? -1 : 1
        }
      }
    )
  }

  const renderActivities = () => {
    return getSortedActivities().map((activity, index) => 
      <ActivityTableActivity 
        key={`activity-${activity.id}`}
        activity={activity}
        attemptDeleteActivity={attemptDeleteActivity}
        attemptEditActivity={attemptEditActivity}
        attemptOpenQrMenu={attemptOpenQrMenu}
      />
    );
  }

  const attemptOpenQrMenu = (id) => {
    setQrModalOpen(true)
    setEditingActivityId(id)
  }

  const onSwapItems = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextActivities = swap(getSortedActivities(), sourceIndex, targetIndex)
    nextActivities.map((x,i) => x.sort_order = i)
    setActivities(nextActivities)
    fetch('/admin/api/activities/sort_order', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ids: nextActivities.map(x=>x.id)
      })
    })
    .then(res => res.json())
    .then(data => {
      if(!data.error) {
        fetchActivities()
      }else {
        alert("An Error Occurred Saving Activities")
        console.log(data.error)
      }
    });
  }
  
  const renderActions = () => {
    return (
      <Stack 
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        style={{margin: "10px 0 10px 0"}}
      >
        <Button variant="contained" color="primary" onClick={() => setEditActivityOpen(true)}>Add Activity</Button>
        <Button variant="contained" color="primary" onClick={() => fetchActivities()}>Refresh</Button>
      </Stack>
    )
  }

  renderQrModal = () => {
    return (
      <GeneralModal
        open={qrModalOpen}
        closeModal={() => {setQrModalOpen(false); setEditActivityOpen(false); setEditingActivityId(null)}}
        key={`qr-modal-activity-${editingActivityId}`}
      >
        <QrModalContents id={editingActivityId}/>
      </GeneralModal>
    );
  }

  const renderEditModal = () => {
    return (
      <GeneralModal
        open={editActivityOpen}
        closeModal={() => {setQrModalOpen(false); setEditActivityOpen(false); setEditingActivityId(null)}}
      >
        <ActivityForm
          key={`activity-${editingActivityId}`}
          id={editingActivityId}
          successCallback={() => {
            setEditActivityOpen(false);
            setEditingActivityId(null);
            fetchActivities()
          }}
          cancelCallback={() => {
            setEditActivityOpen(false);
            setQrModalOpen(false);
            setEditingActivityId(null);
          }}
        />
      </GeneralModal>
    )
  }

  if(loading) {
    return (
      <div>Loading...</div>
    )
  }else {
    return (
      <div>
        {renderActions()}
        {renderEditModal()}
        {renderQrModal()}
      <GridContextProvider onChange={onSwapItems}>
        <GridDropZone
          id="items"
          boxesPerRow={2}
          rowHeight={120}
          style={{ height: "1000px" }}
        >
          {renderActivities()}
        </GridDropZone>
      </GridContextProvider>
      </div>
    )
  }

}

export default ActivitiesTable