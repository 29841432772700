import React, { useState } from 'react';

const QrScanSuccessModalContent = () => {
  return (
    <>
      <div className="text-center text-black text-lg font-light">
        <img src="/images/check.svg" className="
          w-10 h-10 
          mb-[12px]
          inline-block
        "/>
      </div>
      <div className="text-center text-black text-lg font-light">
        Congratulations!
      </div>
      <div className="mt-[7px] text-center text-black text-sm font-light">
        You are on your way to breaking out of the agreement trap.
      </div>
    </>
  )
}

export default QrScanSuccessModalContent