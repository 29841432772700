import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Link
} from '@mui/material';
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';

const QrModalContents = ({
  id
}) => {
  const [loading, setLoading] = useState(true)
  const [activity , setActivity] = useState(null)

  useEffect(() => {
    fetchActivity()
  }, [])

  const download = () => {

    const element = document.getElementById(`qr-code-${id}`)
    html2canvas(element).then((canvas) => {
      console.log("TEST");
      const href = canvas.toDataURL("image/png");
      const download = id + "-QR.png";

      const anchor = document.createElement("a");
      anchor.href = href;
      anchor.target = "_blank";
      anchor.download = download;

      // Auto click on a element, trigger the file download
      anchor.click();
  });

}

  const fetchActivity = async () => {
    fetch(`/admin/api/activities/${id}`)
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setActivity(data.activity)
          setLoading(false)
        }else {
          alert("An Error Occurred Loading Activity")
          console.log(data.error)
        }
      }).catch((error) => {
        alert("An Error Occurred Loading Activity")
        console.log(error)
      })
  }


  const getQrUrl = () => {
    return `https://discover24-hunt.docusignevents.com/qr/${activity.qr_id}`
  }

  if(loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }else {
    return (
      <div>
        <Card>
          <CardContent>
            <div className="">
              <Typography variant="h5" component="div">
                URL
              </Typography>
              <Typography variant="h6" component="div">
                <Link href={getQrUrl()} to={getQrUrl()}>{getQrUrl()}</Link>
              </Typography>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }}>
          <CardContent className='text-center'>
              <Typography variant="h5" component="div">
                QR
              </Typography>
              <div id={`qr-code-${id}`} className="m-auto inline-block text-center qr-code cursor-pointer" onClick={download}>
                <QRCode
                  value={getQrUrl()}
                />
              </div>
              <div>
                <Button color="primary" variant="contained" onClick={download}> Download QR Code </Button>
              </div>
          </CardContent>
        </Card>

      </div>
    );
  }
}

export default QrModalContents