import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import UsersDashboard from './users/UsersDashboard';
import PlayersDashboard from './players/PlayersDashboard';
import ActivitiesDashboard from './activities/ActivitiesDashboard';
import MobilePreviewDashboard from './mobile_preview/MobilePreviewDashboard';
const AdminRouter = () => {
  return (
    <Switch>
      <Route path="/activities">
        <ActivitiesDashboard />
      </Route>
      <Route path="/users">
        <UsersDashboard />
      </Route>
      <Route path="/players">
        <PlayersDashboard />
      </Route>
      <Route path="/preview">
        <MobilePreviewDashboard />
      </Route>
      <Route path="/">
        <ActivitiesDashboard />
      </Route>
    </Switch>
  );
};

export default AdminRouter