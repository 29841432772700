import React, { Component } from 'react';
import UrlContext from './context/UrlContext';
import UserContext from './context/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro'
import AdminRouter from './AdminRouter';
import AdminLayout from "./AdminLayout";

const Admin = (props) => {
  const MUI_KEY = "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
  LicenseInfo.setLicenseKey(MUI_KEY);
  const { apiRoot, rootUrl, user } = props;
  const adminUrlBase = `/admin`;

  const urlContext = {
    apiRoot,
    rootUrl,
    adminUrlBase
  }

  const userContext = {
    user
  };


  return (
    <UrlContext.Provider value={urlContext}>
        <UserContext.Provider value={userContext}>
          <BrowserRouter basename={adminUrlBase}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ConfirmProvider>
                <AdminLayout>
                  <AdminRouter />
                </AdminLayout>
              </ConfirmProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </UserContext.Provider>
    </UrlContext.Provider>
  );
};

export default Admin