import React, { useState } from 'react';
import Modal from "react-modal";
import UserForm from './form/UserForm';

const UserFormModal = ({
  open,
  closeModal,
  callbackSuccess
}) => {
  console.log({
    callbackSuccess
  })
  return (
    <Modal
      isOpen={open}
      onRequestClose={
        closeModal
      }
      contentLabel="User Form"
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1201
        },
        content: {
          width: "600px",
          margin: "auto",
        }
      }}
     
    >
      <UserForm user={{}} cancel={closeModal}  callbackSuccess={callbackSuccess}/>
    </Modal>
  );
};

export default UserFormModal