import React, { useState, useEffect } from 'react';
import {Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import {
  Stack,
  Typography,
  Button
}  from '@mui/material';
const UserForm = ({user, cancel, callbackSuccess}) => {
  console.log({
    callbackSuccess
  })
  const [submitting, setSubmitting] = useState(false)
  const attemptSubmit = (values, formikProps) => {
    console.log(
      values,
      formikProps
    )
    const formikSetSubmitting = formikProps.setSubmitting
    if(submitting) return;
    setSubmitting(true)
    if(!values.password) {
      delete values.password
      delete values.password_confirmation
    }
    fetch(`/admin/api/users/${user.id ? user.id : ''}`, {
      method: user.id ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: values
      })
    }).then(x=> x.json()).then(data =>{
      if(!data.error) {
        callbackSuccess(data.user)
      }else {
        alert(data.error)
      }
      setSubmitting(false)
      formikSetSubmitting(false)
    }).catch((error) => {
      alert("An Error Occurred Saving User")
      console.log(error)
      setSubmitting(false)
      formikSetSubmitting(false)
    })
  }

  const inputFieldClasses="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

  return (
    <Formik
      initialValues={{
        user: {
          email: user.email,
          password: '',
          password_confirmation: ''
        }
      }}
      onSubmit={attemptSubmit}
    >
      <Form>
        <Typography variant="h5">
          {user.id ? "Edit User" : "Create User"}
        </Typography>
        <Stack spacing={2}>
        <Field label="Email" component={TextField}  name="email" placeholder="Email" />

        <Field label="Password" component={TextField}  name="password" type="password" placeholder="Password" />
        <Field label="Confirm Password" component={TextField}  name="password_confirmation" type="password" placeholder="Confirm Password" />
        
        </Stack>
        <div className="mt-2" style={{marginTop: "1rem"}}>
          <Button type="submit" variant="contained" disabled={submitting}>{submitting ? "Submitting..." : "Submit"}</Button>
          <Button onClick={cancel} variant="outlined">Cancel</Button>
        </div>
      </Form>
    </Formik>
  )
}

export default UserForm;