import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography
} from '@mui/material';
import ActivitiesTable from './ActivitiesTable';
const ActivitiesDashboard = () => {
  return (
    <Card sx={{
      m: 2
    }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Activities
        </Typography>
        <ActivitiesTable />
      </CardContent>
    </Card>
  )
}

export default ActivitiesDashboard