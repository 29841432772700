import React, {
  useContext
} from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import Dashboard from './dashboard/Dashboard';
import WinnerPage from './winner/WinnerPage';
import PlayerContext from './context/PlayerContext';
import LoginPage from './login/LoginPage';
import QrScanPage from './qr/QrScanPage';
const GameRouter = () => {
  const { player } = useContext(PlayerContext);

  if(!player) {
    return (
      <LoginPage />
    )
  }
  return (
    <Switch>

      <Route path="/scan">
        <QrScanPage />
      </Route>
      <Route path="/winner">
        <WinnerPage />
      </Route>
      <Route path="/qr/:qrId">
        <Dashboard />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>

    </Switch>
  );
};

export default GameRouter