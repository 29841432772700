import React, {
  useState
} from 'react';
import GameModal from './modal/GameModal';
const GameLayout = ({ children }) => {
  const [ helperModalOpen, setHelperModalOpen ] = useState(false);
  const renderHeader = () => {
    return (<img src="/images/header.svg"/>);
  }

  const renderHelper = () => {
    return (
      <>
        <img onClick={() => setHelperModalOpen(true)} src="/images/game-helper.svg" className="game-layout-helper-icon"/>
        <GameModal
          open={helperModalOpen}
          closeModal={() => setHelperModalOpen(false)}
        >
          <div className="text-center text-black text-lg font-light">
          <img src="/images/game-helper.svg" className="
            w-10 h-10 
            mb-[12px]
            inline-block
          "/>
          </div>
          <div class="text-center text-black text-lg font-light">How to play</div>
          <div class="mt-[7px] text-center text-black text-sm font-light">
            Find and scan all 6 Discover QR codes hidden about the 8th floor.
            <br/>
            <br/>
            Use the clues on the game dashboard to help you on your journey!
          </div>
        </GameModal>
      </>
    );
  }
  return (
    <div className='game-layout'>
      <div className="game-layout-header">
        {renderHeader()}
      </div>
      <div className="game-layout-content">
      {children}
      </div>
      {renderHelper()}
    </div>
  );
};

export default GameLayout