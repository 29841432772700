import React from 'react';
import {
  Button,
  Toolbar,
  Typography,
  AppBar,
  Container,
  Box,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Divider
} from '@mui/material';
import { Drawer } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

const AdminLayout = ({ children }) => {
  const drawerWidth = 240;
  const [ sideBarOpen, setSideBarOpen ] = React.useState(true);

  const renderHeader = () => {
    return (
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={()=>setSideBarOpen(!sideBarOpen)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Docusign Discover24 Roadmap Admin
          </Typography>
          <Button color="inherit" href="/admins/sign_out">Logout</Button>
        </Toolbar>
      </AppBar>
    )
  }

  handleDrawerClose = () => {
    setSideBarOpen(false);
  };

  handleDrawerOpen = () => {
    setSideBarOpen(true);
  };

  const renderSidebar = () => {

    const sidebarInner = (
      <List>
        <ListItem disablePadding>
          <ListItemButton href={"/admin/activities"}>
            <ListItemText primary={"Activities"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href={"/admin/players"}>
            <ListItemText primary={"Players"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href={"/admin/users"}>
            <ListItemText primary={"Admin Panel Users"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href={"/admin/preview"}>
            <ListItemText primary={"Preview App"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href={"/"}>
            <ListItemText primary={"Live Link"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href={"/admin/console"}>
            <ListItemText primary={"Developer Console"} />
          </ListItemButton>
        </ListItem>
      </List>
    )
    return (
      <Box
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* <Drawer
          variant="temporary"
          open={sideBarOpen}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {sidebarInner}
        </Drawer> */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {sidebarInner}
        </Drawer>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'auto'}}>

      {renderSidebar()}
      <Box
        sx={{ flexGrow: 1, p: 0, m:0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {renderHeader()}

        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout