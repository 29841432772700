import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useState } from 'react';
import DataGridTableToolbar from '../DataGridTableToolbar';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { 
  Button
} from "@mui/material";
import ActivityAssignmentForm from './activity_assignment/ActivityAssignmentForm';
import GeneralModal from '../../GeneralModal';
import Cookies from "universal-cookie";

const PlayersTable = () => {
  const confirm = useConfirm();

  const [ players, setPlayers ] = useState([])
  const [ loading, setLoading ] = useState(true)
  
  const [ editingPlayerId, setEditingPlayerId ] = useState(null)
  const [ editPlayerOpen, setEditPlayerOpen ] = useState(false)

  const openUserEditModal = (id) => {
    setEditingPlayerId(id)
    setEditPlayerOpen(true)
  }

  const closeUserEditModal = () => {
    setEditingPlayerId(null)
    setEditPlayerOpen(false)
  }
  
  useEffect(() => {
    fetchPlayers()
  }, [])

  const fetchPlayers = () => {
    fetch('/admin/api/players')
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setPlayers(data.players)
          setLoading(false)
        }else {
          alert("An Error Occurred Loading Players")
          console.log(data.error)
        }

      }).catch((error) => {
        alert("An Error Occurred Loading Players")
        console.log(error)
      })
  }

  if(loading) {
    return (
      <p>Loading...</p>
    )
  }
  
  const columns = [
    { field: "name_first", headerName: "First Name", flex: 1,  editable: true  },
    { field: "name_last", headerName: "Last Name", flex: 1,  editable: true},
    { field: 'email', headerName: 'Email', editable: true, },
    { field: 'login_key', headerName: 'Unique Login Code', editable: true },
    { field: 'created_at', sort: true, headerName: 'Created At', type: 'dateTime', flex: 1, valueGetter: (params) => new Date(params.value) },
    { field: "actions", flex: 2, headerName: "Actions", renderCell: (params) => (
      <>
        <Button onClick={() => becomePlayer(params.row.login_key)}>Become Player</Button>
        <Button onClick={() => openUserEditModal(params.row.id)}>Manage</Button>
        <Button onClick={() => attemptDeletePlayer(params.row.id)}>Delete</Button>
      </>   
    )}
  ];

  const becomePlayer = (loginKey) => {
    const cookies = new Cookies();
    cookies.set("login_key", loginKey, { path: "/", sameSite: true });
    window.location = '/admin/preview'
  }

  const attemptDeletePlayer = (id) => {
    confirm({
      description: "Are you sure you want to delete this player? This action cannot be undone",
      confirmationText: "Yes, delete",
      cancellationText: "No, keep",
    }).then(() => {
      fetch(`/admin/api/players/${id}`, {
        method: "DELETE",
      }).then(fetchPlayers);
    })
  }


  const saveUser = (
    newRow,
    oldRow
  ) => {

    fetch(`/admin/api/players/${oldRow.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        player: {
          name_first: newRow.name_first,
          name_last: newRow.name_last,  
          email: newRow.email,
          login_key: newRow.login_key
        }
      }),
    })
    return newRow
  }

  const renderAssignmentModal = () => {
    if(editingPlayerId && editPlayerOpen) {
      return (
        <GeneralModal
          open={editPlayerOpen}
          closeModal={closeUserEditModal}
        >
          <ActivityAssignmentForm
            id={editingPlayerId}
            cancelCallback={() => {
              closeUserEditModal()
              fetchPlayers()
            }}
          />
        </GeneralModal>
      )
    }
  }

  return (
    <>
    <Button
      onClick={() => {setLoading(true); fetchPlayers()}}
    > Refresh </Button>
    {renderAssignmentModal()}
    <DataGridPremium
      //onCellEditCommit={saveComments}
      autoHeight
      unstable_headerFilters
      processRowUpdate={saveUser}
      onProcessRowUpdateError={(error) => console.log(error)}
      rows={players}
      columns={columns}
      slots={{
        toolbar: DataGridTableToolbar
      }}
      checkboxSelection={true}
      style={{
        cell: {
          paddingBottom: 0,
          paddingRight: 0,
          whiteSpace: 'normal',
          overflow: 'visible',
        },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'created_at', sort: 'desc' }],
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
        columns: {
          columnVisibilityModel: {
            hidden: false,
          },
        },
        filter: {
          filterModel: {
            items: [
              {
                field: 'hidden',
                operator: 'is',
                value: "false",
              }
            ]
          }
        }
      }}
      pageSizeOptions={[5, 10, 20, 50]}
    />
    </>
  );
};

export default PlayersTable