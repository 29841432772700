import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { 
  Stack,
  Typography,
  Button
}  from '@mui/material';
import {useDropzone} from 'react-dropzone'

const ActivityForm = ({id, cancel, successCallback}) => {
  const [ activity, setActivity ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ imagePreviewUrl, setImagePreviewUrl ] = useState(null)
  const [ imageBlob, setImageBlob ] = useState(null)

  const fetchActivity = async () => {
    if(!id){
      setActivity(null)
      setLoading(false)
    }else {
      fetch(`/admin/api/activities/${id}`)
        .then(res => res.json())
        .then(data => {
          if(!data.error) {
            setActivity(data.activity)
            setImagePreviewUrl(data.activity.image_url)
            setLoading(false)
          }else {
            alert("An Error Occurred Loading Activity")
            console.log(data.error)
          }
        }).catch((error) => {
          alert("An Error Occurred Loading Activity")
          console.log(error)
        })
    }
  }

  useEffect(() => {
    fetchActivity()
  }, [id])

  const isEditing = () => {
    return id ? true : false
  }

  const attemptSubmit = (values, formikProps) => {
    const formData = new FormData();
    Object.keys(values.activity).map(key => {
      formData.append(`activity[${key}]`, values.activity[key])
    })

    if(imageBlob) {
      formData.append('activity[image]', imageBlob)
    }

    fetch(`/admin/api/activities/${isEditing() ? id : ''}`, {
      method: isEditing() ? "PATCH" : "POST",
      body: formData
    }).then(x=> x.json()).then(data =>{
      if(!data.error) {
        successCallback ? successCallback(data.activity) : null
      }else {
        alert(data.error)
        console.log({
          data
        })
      }
    }).catch((error) => {
      console.log(error)
      alert("An Error Occurred Saving Activity")
    })
  }

  const getInitialValues = () => {
    return {
      activity: {
        name: activity ? activity.name : '',
        clue: activity ? activity.clue : '',
      }
    }
  }

  const renderForm = (formikProps) => {
    return (
      <Form>
        <Stack spacing={2}>
          <Field
            component={TextField}
            name="activity[name]"
            label="Name"
            type="text"
            variant="outlined"
            size="small"
          />
          <Field
            component={TextField}
            name="activity[clue]"
            label="Clue"
            type="text"
            variant="outlined"
            size="small"
            multiline
            rows={4}
          />
          {/* <label>
            Tile Image
          </label>
          {renderDropzone(formikProps)} */}

        </Stack>
        {renderButtons(formikProps)}
      </Form>
    )
  }

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader()
    reader.onload = () => {
      setImagePreviewUrl(reader.result)
      setImageBlob(acceptedFiles[0])
    }
    reader.readAsDataURL(acceptedFiles[0])
  });

  const renderDropzone = (formikProps) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
      <div
        {...getRootProps()}
        className="activity-form-dropzone"
        style={{
          backgroundImage: imagePreviewUrl ? `url(${imagePreviewUrl})` : null,
        }}
      >
        <input
          {...getInputProps()}
        />
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            marginTop: "10px"
          }}
        >
          {!imagePreviewUrl ? (
            <>
              {isDragActive ?
                "Drop the files here ..."
                :
                "Click here or drag tile image here to upload"
              }
            </>
          ):""}
          
        </Typography>
      </div>
    )
  }

  const renderButtons = (formikProps) => {
    return (
      <Stack direction="row" spacing={2} sx={{
        marginTop: "10px"
      }}>
        <Button
          variant="contained"
          color="primary"
          onClick={formikProps.submitForm}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={cancel}
        >
          Cancel
        </Button>
      </Stack> 
    )
  }

  if(loading) {
    return <div>Loading...</div>
  }else {
    return (
      <Formik
        initialValues={getInitialValues()}
        onSubmit={attemptSubmit}
      >
        {(formikProps) => renderForm(formikProps)}
        
      </Formik>
    )
  }
}

export default ActivityForm
