import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography
} from '@mui/material';
import UsersTable from './UsersTable';
const UsersDashboard = () => {
  return (
    <Card sx={{
      m: 2
    }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Admin Users
        </Typography>
        <UsersTable />
      </CardContent>
    </Card>
  )
}

export default UsersDashboard