
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { 
  Stack,
  Typography,
  Button,
  Checkbox,
  FormControlLabel
}  from '@mui/material';
import Cookies from 'universal-cookie';

const LoginPage = () => {
  const [ error, setError ] = useState(null);

  const setPlayer = (player) => {
    const cookies = new Cookies();
    cookies.set("login_key", player.login_key, { path: "/", sameSite: true, expires: new Date(Date.now()+2592000)});
    location.reload()
  }

  const attemptSubmit = (values, formikProps) => {
    fetch(`/api/players/register`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res.json())
    .then(data => {
      if(!data.error && data.player) {
        setError(null)
        setPlayer(data.player)
      }else {
        setError(data.error || "An Error Occurred.")
      }
    }).catch((error) => {
      alert("An Error Occurred.")
      console.log(error)
    })
  }

  const renderFormik = () => {
    return (
      <Formik 
        initialValues={{
          player: {
            name_first: "",
            name_last: "",
            email: ""
          }
        }}
        onSubmit={attemptSubmit}
      >
        {(formikProps) => (
          <Form>
            <div className="text-center mx-16">
              <Stack spacing={2}>
                <Field
                  component={TextField}
                  name="player[name_first]"
                  label="First"
                  type="text"
                  variant="outlined"
                  size="small"
                  sx={{ input: { borderRadius: "6px", background: 'white' } }}
                  required
                />
                <Field
                  component={TextField}
                  name="player[name_last]"
                  label="Last"
                  type="text"
                  variant="outlined"
                  size="small"
                  required
                  sx={{ input: { borderRadius: "6px", background: 'white' } }}
                />
                <Field
                  component={TextField}
                  name="player[email]"
                  label="Email"
                  type="text"
                  variant="outlined"
                  size="small"
                  sx={{ input: { borderRadius: "6px", background: 'white' } }}
                  required
                />
              </Stack>
              <Button className="mt-6" type="submit" variant="contained" color="primary">
                Let's Play!
              </Button>
              <div className="relative login-graphics-anchor w-full">
                <img src="/images/login-image-1.png" className="absolute left-[-86px] top-[30px]"/>
                <img src="/images/login-image-2.png" className="absolute" style={{
                  left: "calc(100% - 20px)"
                }}/>
              </div>
              <div className="mt-6 text-center text-white">
                {error}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  

  
  return (
    <div>
      <div className="text-center">
        <div className="text-white text-xl font-light">
          Welcome to the Docusign Discover QR Code Scavenger Hunt<br/>
        </div>
        <div className="mt-2 mb-6 text-white text-base font-light">
          Enter your information to play the game:
        </div>
      </div>
      {renderFormik()}
    </div>
  )
}

export default LoginPage