import React, { useState, useEffect } from 'react';
import { useHistory, useParams} from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link
} from '@mui/material';

import QrCodeScannedModal from '../qr/QrCodeScannedModal';
const Dashboard = () => {
  let { qrId } = useParams();
  let history = useHistory();
  const [ activities, setActivities ] = useState([]);
  const [ player, setPlayer ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ activityModalOpen, setActivityModalOpen ] = useState(false);
  const [ scannedActivityQrId, setScannedActivityQrId ] = useState(null);

  useEffect(() => {
    if(qrId) {
      openActivityModal(qrId);
    }
  }, []);

  const fetchActivitiesAndPlayer = async () => {
    const activitiesResponse = await fetch('/api/game/get_player_and_activities');
    if(activitiesResponse.status == 200) {  
      const activitiesData = await activitiesResponse.json();
    
      setPlayer(activitiesData.player);
      setActivities(activitiesData.activities);
      setLoading(false);
    }else {
      //Log Out?
    }
  }
  

  useEffect(() => {
    fetchActivitiesAndPlayer();
  }, []);

  const renderScanAQrCode = () => {
    return (
      <div className="text-center">
        <Button component={Link} href="/scan" variant="contained" color="primary">
          <img src="/images/camera.svg" className="mb-[2px] mr-[7px] w-[13px] inline-block"/>
          Scan A QR Code
        </Button>
      </div>
    )
  }


  getSortedActivities = () => {
    return activities.sort((a,b) => {
        if(a.sort_order == b.sort_order) {
          return a.id < b.id ? -1 : 1
        }else {
          return a.sort_order < b.sort_order ? -1 : 1
        }
      }
    )
  }

  const hasPlayerAchievedActivity = (activity) => {
    return player.activities.map(x=>
      x.id
    ).indexOf(
      activity.id
    ) > -1
  }
  

  const renderActivities = () => {
    return activities.map((activity, index) => {
      return (
        <div className={`sg-activity-card ${hasPlayerAchievedActivity(activity) ? 'flipped' : ''}`} key={index}>
          <div className="sg-activity-card-inner">
            <div className="sg-activity-card-title">
              {activity.clue}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderActivityImage = () => {
    return (
      <div className="sg-activity-image-container">
        <img src="/images/winner-image-demo.png" className="sg-activity-image"/>
      </div>
    )
  }
  
  const renderQRScannedModal = () => {
    if(activityModalOpen) {
      return (
        <QrCodeScannedModal 
          open={activityModalOpen}
          qrId={scannedActivityQrId}
          closeModal={closeActivityModal}

        />
      )
    }
  }


  const closeActivityModal = (giftedAward) => {
    //Give Player award
    if(giftedAward) {
      player.activities.push(giftedAward)
      setPlayer(player)
    }




    //Close modal and reset
    history.replace({ pathname: `/`})

    //if player has all awards set game complete
    if(giftedAward && activities.filter(x=> !hasPlayerAchievedActivity(x)).length == 0) {
      history.replace({ pathname: `/winner`})
      return
    }
    setActivityModalOpen(false);
    setScannedActivityQrId(null);
  }


  const openActivityModal = (activityQrId) => {
    setScannedActivityQrId(activityQrId);
    setActivityModalOpen(true);
  }


  if(loading) {
    return (
      <div className="text-center mt-10">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      {renderScanAQrCode()}
      {renderQRScannedModal()}
      <div className="sg-acivities-container mt-[20px] px-[20px] relative">
       <div>
        {renderActivityImage()}
          <div className="sg-acivities-container-tiles">
            {renderActivities()}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Dashboard