import React, { useState } from 'react';
import {Scanner, useDevices} from '@yudiel/react-qr-scanner'
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Link } from '@mui/material';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { X } from '@mui/icons-material';

const QrScanPage = () => {
  const devices = useDevices()
  const [ deviceIndex, setDeviceIndex ] = useState(0);
  if(!Array.isArray(devices) || devices.length == 0) {
    return (
      <div className="text-center p-8 pt-0">
        <div className="mt-2 mx-auto text-center">
          <Button component={Link} href="/" variant="contained" color="primary">
            Return to Dashboard
          </Button>
        </div>
        <CircularProgress />
        <div className="text-white mt-2 text-black text-lg font-light">
          Please allow access to your camera
          or use your native camera app to scan the QR codes
        </div>
        <div className="mt-8 mx-auto text-center">
          <Button component={Link} href="/" variant="contained" color="primary">
            Return to Dashboard
          </Button>
        </div>
      </div>
    )
  }


  const handleError = (err) => {
    console.error(err)
  }
  
  const handleScan = (data) => {
    if(data && data.length > 0) {
      const rawValue = data[0].rawValue
      if(rawValue.indexOf("/qr/") > -1) {
        const dataSplit = rawValue.split("/qr/");
        if(dataSplit.length > 1) {
          const qrId = dataSplit[1]
          window.location.href = `/qr/${qrId}`
          return
        }
      }
    }
  }

  const renderCameraSwapButton = () => {
    if(devices.length > 1) {
      return (
        <Button
          onClick={() => {
            setDeviceIndex((current) => (current + 1) % devices.length)
          }}
          variant="contained"
          color="primary"
          className="absolute top-2 left-2 z-10"
          startIcon={<CameraswitchIcon />}
        >
        </Button>
      )
    }
  }

  return (
    <div className="relative">
      <div className="mt-2 mb-8 mx-auto text-center">
        <Button component={Link} href="/" variant="contained" color="primary">
          Return to Dashboard
        </Button>
      </div>
      <Scanner
        onError={handleError}
        onScan={handleScan}
        style={{
          height: "calc(100vh - 240px)"
        }}
        allowMultiple={true}
        formats= {["qr_code"]}
        facingMode="environment"
        components={{ audio: false, }}
      />


    </div>
  )
};

export default QrScanPage