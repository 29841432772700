import { QrCode } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import QrScanSuccessModalContent from '../qr/QrScanSuccessModalContent';
import QrScanFailedModalContent from '../qr/QrScanFailedModalContent';
import QrScanAlreadyRecievedModal from '../qr/QrScanAlreadyRecievedModal';
import GameModal from '../modal/GameModal';

const QrCodeScannedModal = ({
  open,
  closeModal,
  qrId
}) => {
  const [ activity, setActivity ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ newActivity, setNewActivity ] = useState(null);
  useEffect(() => {
    console.log({
      loading
    })
    setLoading(true)

    fetch(`/api/game/scan_qr`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          qr_id: qrId
        })
      }
    ).then(x=> x.json())
    .then(data => {
      if(!data.error) {
        setActivity(data.activity)
        setLoading(false)
        setNewActivity(data.new_record)
      }else {
        setLoading(false)
        setActivity(null)
        setNewActivity(true)
      }
    })
  }, [])


  const renderActivityModalContent = () => {
    if(newActivity) {
      return <QrScanSuccessModalContent
        activity={activity}
      />
    }else {
      return <QrScanAlreadyRecievedModal />
    }
  }

  if(!loading) {
    return (
      <GameModal
        closeModal={()=>closeModal(activity)}
        open={true}
      >
        {activity ? renderActivityModalContent() : <QrScanFailedModalContent/>}
      </GameModal>
    )
  }
}

export default QrCodeScannedModal