import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid, Button, Card, CardContent, Typography, CardActions
} from '@mui/material';

const ActivityAssignmentForm = ({id}) => {
  const [ activities, setActivities ] = useState(null);
  const [ player, setPlayer ] = useState(null);

  useEffect(() => {
    fetchActivities()
    fetchPlayer()
  }, [])

  const fetchPlayer = () => {
    fetch(`/admin/api/players/${id}`)
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setPlayer(data.player)
        }else {
          alert("An Error Occurred Loading Player")
          console.log(data.error)
        }
      }).catch((error) => {
        alert("An Error Occurred Loading Player")
        console.log(error)
      })
  }

  const fetchActivities = () => {
    fetch('/admin/api/activities')
      .then(res => res.json())
      .then(data => {
        if(!data.error) {
          setActivities(data.activities)
        }else {
          alert("An Error Occurred Loading Activities")
          console.log(data.error)
        }
      }).catch((error) => {
        alert("An Error Occurred Loading Activities")
        console.log(error)
      })
  }

  const getSortedActivities = () => {
    return activities.sort((a,b) => {
        if(a.sort_order == b.sort_order) {
          return a.id < b.id ? -1 : 1
        }else {
          return a.sort_order < b.sort_order ? -1 : 1
        }
      }
    )
  }

  const renderActivity = (activity, index) => {
    return (
      <Grid item xs={6}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="div">
              {activity.name}
            </Typography>
            <Typography >
              {activity.clue}
            </Typography>
          </CardContent>
          <CardActions >
            {hasAchievedActivity(activity) ? 
              <Button onClick={()=>removeAward(activity)} fullWidth variant="contained" color="error" size="small">Remove Award</Button>
              :
              <Button onClick={()=>giveAward(activity)} fullWidth variant="contained" color="primary" size="small">Give Award</Button>   
            }
          </CardActions>
        </Card>
      </Grid>
    )
  }

  const giveAward = (activity) => {
    fetch(`/admin/api/players/${id}/add_activity?activity_id=${activity.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => res.json())
    .then(data => {
      if(!data.error) {
        fetchPlayer()
      }
    });
  }

  const removeAward = (activity) => {
    fetch(`/admin/api/players/${id}/remove_activity?activity_id=${activity.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => res.json())
    .then(data => {
      if(!data.error) {
        fetchPlayer()
      }
    });
  }

  const hasAchievedActivity = (activity) => {
    const player_activities = player.activities
    return player_activities.map(x=>x.id).indexOf(activity.id) > -1
  }

  const renderActivities = () => {
    return getSortedActivities().map((activity, index) => renderActivity(activity, index))
  }
  if(!activities || !player) {
    return <div>Loading...</div>
  }
  return (
    <Grid container  display="flex" spacing={2}>
      {renderActivities()}
    </Grid>
  )
}

export default ActivityAssignmentForm