import React, { useState, useEffect, useContext } from 'react';
import { Link, Button, CircularProgress } from '@mui/material'
import playerContext from '../context/PlayerContext';

const WinnerPage = () => {
  const { player } = useContext(playerContext)
  const [ page, setPage ] = useState(0)
  useEffect(() => {
    checkWinner()
    //document.getElementById("winner-video").play()
  }, [])

  useEffect(() => {
    if(page == 1) {
     //document.getElementById("winner-video").play()
    }
  }, [page])

  const checkWinner = async () => {
    fetch('/api/game/check_winner').then(res => res.json()).then(data => {
      if(!data.error) {
        if(data.winner) {
          setPage(1)
        }
      }else {
        document.location = '/'
      }

    })
  }



  switch(page) {
    case 0:
      return (
        <div className="mt-8 text-center">
          <CircularProgress/>
        </div>
      );
    case 1:
      return (
        <video
          id="winner-video"
          playsInline
          className="w-full"
          autoPlay muted
          {...{"autoPlay": true}}
          onLoadedData={() => {console.log("loaded")}}
          onEnded={() => {setPage(2); console.log("ended")}}>
          <source
            src={'https://s3.amazonaws.com/assets.jagcdn.net/docusign/discover24/DS_Disc24_App_CompletionAnimation.mp4'}
            type="video/mp4"
          />
        </video>
      )
    default:
      return (
        <>
          <div class="text-center text-white mt-[70px] text-[28px] font-light">
            Congratulations<br/>{player.name_first} {player.name_last} 🎉
            <br/>
            You've unlocked the agreement trap!
            <br/>
            <br/>
            Time to claim your swag!
          </div>
        </>
      );
    }
}

export default WinnerPage