import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography
} from '@mui/material';
import PlayersTable from './PlayersTable';
const PlayersDashboard = () => {
  return (
    <Card sx={{
      m: 2
    }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Players
        </Typography>
        <PlayersTable />
      </CardContent>
    </Card>
  )
}

export default PlayersDashboard