import React, { useState } from 'react';
import Modal from "react-modal";

const GeneralModal = ({
  open,
  closeModal,
  children
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={
        closeModal
      }
            className="admin-modal"
      overlayClassName="react-modal-overlay"
      contentLabel="Modal"
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1201
        },
        content: {
          width: "600px",
          margin: "auto",
        }
      }}
    >
      {children}
    </Modal>
  );
};

export default GeneralModal